import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { VoucherClient, IBulkVoucherValidationResponse, FileParameter, MessageType } from "../../services/voucher/vouchers-service";

@Component({
  selector: 'add-marketing-vouchers.component',
  templateUrl: './add-marketing-vouchers.component.html',
  styleUrls: ['./add-marketing-vouchers.component.css']
})

export class AddMarketingVouchersComponent implements OnInit {

  private hasPermissions: boolean = false;
  public successAlerts: any[] = [];
  public failureAlerts: any[] = [];
  public isFileValid: boolean = false;
  public isFileLoaded: boolean = false;
  public isValidationResponseLoaded: boolean = false;
  public fileInput: any;
  public file: File;
  public validationResponse: IBulkVoucherValidationResponse;
  public errorMessageType: string = MessageType[MessageType.Error];
  public warningMessageType: string = MessageType[MessageType.Warning];

  constructor(
    private voucherClient: VoucherClient,
    private router: Router) {
  }

  ngOnInit(): void {
    this.voucherClient.me().subscribe((data) => {
      this.hasPermissions = data.includes("MarketingVouchersManager");
      if (this.hasPermissions === false)
        this.router.navigate(['/unauthorized']);
    });
  }

  onFileChange(evt: Event) {

    this.fileInput = evt.target as HTMLFormElement;
    this.file = this.fileInput.files[0];
    this.isFileLoaded = true;
  }

  validateFile() {

    let fileParameter = { data: this.file, fileName: this.file.name } as FileParameter;
    this.voucherClient.validate(fileParameter).subscribe(validationResponse => {
      this.validationResponse = validationResponse;
      this.isValidationResponseLoaded = true;
      this.isFileValid = !this.validationResponse.validationMessages.some(x => x.type.toString() === this.errorMessageType);
      this.fileInput.value = '';
      this.isFileLoaded = false;
    },
      err => {
        this.fileInput.value = '';
        this.isFileLoaded = this.isValidationResponseLoaded = this.isFileValid = false;
        this.showErrorMsg("Something went wrong! Please check the file and try again.");
      });
  }

  uploadFile() {

    let fileParameter = { data: this.file, fileName: this.file.name } as FileParameter;
    this.voucherClient.upload(fileParameter).subscribe(() => {

      this.fileInput.value = '';
      this.isFileLoaded = this.isValidationResponseLoaded = this.isFileValid = false;
      this.showSuccessMsg();
    },
      err => {
        this.fileInput.value = '';
        this.isFileLoaded = this.isValidationResponseLoaded = this.isFileValid = false;
        this.showErrorMsg("Something went wrong! Please check the file and try again.");
      });
  }

  public downloadTemplate(): void {

    let encodedStr = "Code,Amount,EmailAddress,StartDate,ExpiryDate";

    let a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    //Set utf-8 header to let excel recognize its encoding
    let blob = new Blob(["\ufeff", encodedStr], { type: 'text/csv' });
    a.href = window.URL.createObjectURL(blob);
    a.download = 'MarketetingVouchersTemplate.csv';
    a.click();
  }

  private showErrorMsg(message: string = "Something went wrong!") {
    this.failureAlerts.push({
      type: 'danger',
      msg: message,
      timeout: 10000
    })
  }

  private showSuccessMsg(message: string = "Marketing vouchers have been created successfully.") {
    this.successAlerts.push({
      type: 'success',
      msg: message,
      timeout: 10000
    });
  }
}
