import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { fetchErrorResponseFromBadRequestPipe } from './error-response';

export const VOUCHER_API_BASE_URL = new InjectionToken<string>('VOUCHER_API_BASE_URL');

@Injectable()
export class VoucherClient {
  constructor(private httpClient: HttpClient, @Inject(VOUCHER_API_BASE_URL) private baseUrl: string) {
  }

  upload(vouchersCsvFile: FileParameter): Observable<void> {
    const content = new FormData();
    content.append("vouchersCsvFile", vouchersCsvFile.data, vouchersCsvFile.fileName ? vouchersCsvFile.fileName : "vouchersCsvFile");

    return this.httpClient.post<void>(`${this.baseUrl}/bulk-upload`, content);
  }

  validate(vouchersCsvFile: FileParameter): Observable<IBulkVoucherValidationResponse> {
    const content = new FormData();
    content.append("vouchersCsvFile", vouchersCsvFile.data, vouchersCsvFile.fileName ? vouchersCsvFile.fileName : "vouchersCsvFile");

    return this.httpClient.post<IBulkVoucherValidationResponse>(`${this.baseUrl}/bulk-upload-validate`, content);

  }

  me(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.baseUrl}/user/me`);
  }

  getVouchers(startDateFrom: Date, startDateTo: Date, category: string): Observable<IGetVouchersResponse> {
    let url = `${this.baseUrl}/get-vouchers?StartDateFrom=${startDateFrom.toJSON()}&StartDateTo=${startDateTo.toJSON()}&Category=${category}`;
    const requestOptions = {
      headers: new HttpHeaders({ 'X-VP-Version': '2.0' }),
    };
    return this.httpClient.get<IGetVouchersResponse>(url, requestOptions);
  }

  voucherUpload(voucherRequest: IVoucherRequest): Observable<void> {
    return this.httpClient.put<void>(`${this.baseUrl}/api/v2/vouchers`, voucherRequest)
      .pipe(fetchErrorResponseFromBadRequestPipe());
  }

  voidVoucher(voucherCode: string): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/void/${voucherCode}`, null)
      .pipe(fetchErrorResponseFromBadRequestPipe());
  }
}

export interface IBulkVoucherValidationResponse {
    voucherCount: number;
    validationMessages?: IBulkVoucherValidationMessage[] | undefined;
}

export interface IBulkVoucherValidationMessage {
    message?: string | undefined;
    type: MessageType;
    code?: string | undefined;
    emailAddress?: string | undefined;
}

export enum MessageType {
    Error = 0,
    Warning = 1,
}

export enum BookingTypeRestrictions {
    NoRestriction = 0,
    Accommodation = 1,
    Package = 2,
}

export enum ECommerceItemStatus {
    Empty = 0,
    Reserved = 1,
    Booked = 2,
    Unconfirmed = 3,
    Cancelled = 4,
    Expired = 5,
    Failed = 6,
    Released = 7,
    Voided = 8,
}

export enum VoucherType {
    CustomerServices = 0,
    Marketing = 1,
}

export interface IGetVouchersResponse {
  vouchers?: IGetVoucherResponseModel[] | undefined;
}

export interface IGetVoucherResponseModel {
  code?: string | undefined;
  amount: number;
  startDate: string;
  endDate: string;
  status: ECommerceItemStatus | undefined | null;
  category: string;
  user: string;
  isPromotional: boolean;
  reference: string;
}

export interface IVoucherAppliedParameters {
    code?: string | undefined;
    customerEmail?: string | undefined;
    reference?: string | undefined;
}

export interface IVoucherRequest {
    voucherCode?: string | undefined;
    amount: number;
    startDate: Date;
    endDate: Date;
    bookingTypeRestrictions: BookingTypeRestrictions;
    voucherEligibleAccommodation: boolean;
    voucherUsers?: IVoucherUser[] | undefined;
}

export interface IVoucherUser {
    notes?: string | undefined;
    email?: string | undefined;
}


export interface FileParameter {
    data: any;
    fileName: string;
}

export interface FileResponse {
    data: Blob;
    status: number;
    fileName?: string;
    headers?: { [name: string]: any };
}

