import { Component, OnInit } from '@angular/core';
import { LocalDataSource, Settings } from 'angular2-smart-table';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { VoucherClient, IGetVoucherResponseModel } from '../../services/voucher/vouchers-service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-list-voucher',
  templateUrl: './list-voucher.component.html',
  styleUrls: ['./list-voucher.component.css']
})
export class ListVoucherComponent implements OnInit {
  public failureAlerts: any[] = [];

  vouchers: IGetVoucherResponseModel[];
  vouchersSource: LocalDataSource;
  bsRangeValue: Date[];
  currentDate = new Date();

  @BlockUI('vouchers-list') blockUI: NgBlockUI;

  constructor(
    private voucherClient: VoucherClient) {
    this.vouchersSource = new LocalDataSource([]);
    let minDate = new Date();
    let maxDate = new Date();
    minDate.setDate(this.currentDate.getDate() - 6);
    maxDate.setDate(this.currentDate.getDate());
    this.bsRangeValue = [minDate, maxDate];

    this.filterVouchers();
  }

  ngOnInit() {
  }

  filterVouchers() {
    if (this.bsRangeValue == undefined) {
      this.showErrorMsg('Select a valid date range');
      return;
    }

    let minDate = new Date(this.bsRangeValue[0].setUTCHours(0, 0, 0, 0));
    let maxDate = new Date(this.bsRangeValue[1].setUTCHours(23, 59, 59, 999));
    if (!this.validateDateRane(minDate, maxDate)) {
      return;
    }

    this.blockUI.start();
    this.voucherClient.getVouchers(minDate, maxDate,'')
      .subscribe((data) => {
        this.vouchersSource.load(data.vouchers);
        this.blockUI.stop();
      },
        (error) => {
          this.showErrorMsg("Sorry, but something went wrong");
          this.blockUI.stop();
        });
  }

  public voucherSettings = {
    pager: {
      display: true,
      perPage: 10000000
    },
    columns: {
      code: {
        title: 'Voucher Code',
        width: '15%',
        isFilterable: false
      },
      amount: {
        title: 'Amount',
        width: '10%',
        valuePrepareFunction: (value: any) => {
          return value ? Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value) : null;
        },
        isFilterable: false
      },
      startDate: {
        title: 'Start Date',
        width: '10%',
        valuePrepareFunction: (value: any) => { return new DatePipe('en-GB').transform(value, 'dd/MM/yyyy'); },
        isFilterable: false
      },
      endDate: {
        title: 'End Date',
        width: '10%',
        valuePrepareFunction: (value: any) => { return new DatePipe('en-GB').transform(value, 'dd/MM/yyyy'); },
        isFilterable: false
      },
      category: {
        title: 'Category',
        width: '20%'
      },
      user: {
        title: 'User',
        width: '15%',
        isFilterable: false
      },
      isPromotional: {
        title: 'Promotional',
        width: '5%',
        isFilterable: false
      },
      reference: {
        title: 'Reference',
        width: '25%',
        isFilterable: false
      },
      status: {
        title: 'Status',
        width: '10%',
        isFilterable: false,
        valuePrepareFunction: (value: any) => {
          let voucherStatus = value;

          if (value == "Empty")
            return "Unused";

          if (value == "Booked")
            return "Redeemed";

          return voucherStatus;
        },
      }
    },
    attr: {
      class: 'table table-striped table-bordered'
    },
    actions: {
      delete: false,
      add: false,
      edit: false,
    }
  };

  onDateChange($event: any) {
    if ($event == undefined) {
      this.showErrorMsg('Select a valid date range');
      return;
    }

    if ($event[0] == undefined || $event[1] == undefined) {
      return;
    }

    let minDate = new Date($event[0].toJSON().split('T')[0]);
    let maxDate = new Date($event[1].toJSON().split('T')[0]);
    this.validateDateRane(minDate, maxDate);
  }

  validateDateRane(minDate: Date, maxDate: Date): boolean {
    let days = Math.floor((Date.UTC(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate()) - Date.UTC(minDate.getFullYear(), minDate.getMonth(), minDate.getDate())) / (1000 * 60 * 60 * 24));
    if (days >= 31) {
      this.showErrorMsg('Select a max of 31 days between dates');
      return false
    }
    return true;
  }

  private showErrorMsg(message: string = "Something went wrong!") {
    this.failureAlerts.push({
      type: 'danger',
      msg: message,
      timeout: 2000
    })
  }
}
