<h5 class="card-header text-center pb-3">All Vouchers</h5>
<div *ngFor="let alert of failureAlerts">
  <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
</div>
<div *blockUI="'vouchers-list'">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div>
          <form name="form" (ngSubmit)="voucherForm.form.valid && filterVouchers()" #voucherForm="ngForm" novalidate>
            <div class="row" style="align-items: flex-end">
              <div class="col-md-2">
                <label class="mb-2" for="bsDaterangepicker">Start Data Range:</label>
                <input type="text"
                       name="bsDaterangepicker"
                       placeholder="Select start data from and to"
                       class="form-control"
                       bsDaterangepicker
                       [(ngModel)]="bsRangeValue"
                       #bsDaterangepicker="ngModel"
                       [ngClass]="{ 'is-invalid': voucherForm.submitted && bsDaterangepicker.invalid }"
                       (ngModelChange)="onDateChange($event)"
                       [bsConfig]="{ isAnimated: true,rangeInputFormat : 'DD-MM-YYYY', dateInputFormat: 'DD-MM-YYYY',showWeekNumbers:false }"
                       autocomplete="false">
                <div *ngIf="voucherForm.submitted && bsDaterangepicker.invalid" class="invalid-feedback">
                  <div>Please select the valid date range.</div>
                </div>
              </div>
              <div class="col-md-1">
                <button value="filter" class="btn btn-primary" name="filter" type="submit">
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
        <div><br/></div>
        <div>
          <angular2-smart-table [settings]="voucherSettings" [source]="vouchersSource">
          </angular2-smart-table>
        </div>
      </div>
    </div>
  </div>
</div>
